html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  letter-spacing: 0.10em;
  color: #333666;
}


* {
  box-sizing: border-box;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}